import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

export default function Template({ data }) {
  const { markdownRemark } = data
  const frontmatter = markdownRemark === null ? {} :  markdownRemark.frontmatter;
  const html = markdownRemark === null ? {} :  markdownRemark.html;
  return (
    <div className="note">
      <SEO title={frontmatter.title} />
      <main dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
